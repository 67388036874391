<template>
	<div v-loading="loading">
		<b-form-file
			multiple
			accept="image/jpeg, image/png"
			size="md"
			v-model="list"
			:placeholder="$t('general.select_img')"
			:drop-placeholder="$t('general.drop_file')"
			:browse-text="$t('general.choose')"
			ref="photos"
		>
			<template slot="file-name" slot-scope="{names}">
				<b-badge variant="dark">{{ names[0] }}</b-badge>
				<b-badge v-if="names.length > 1" variant="dark" class="ml-1"> + {{ names.length - 1 }} More files </b-badge>
			</template>
		</b-form-file>
		<p>
			<br />
			<base-button type="primary" size="sm" @click="uploadPictures">{{ $t('general.upload') }}</base-button>
		</p>

		<b-row>
			<b-col md="3" v-for="(photo, index) in photoList" :key="index">
				<b-card no-body class="mt-2">
					<img :src="`${imgUrl}media/thumb_${photo}`" alt="" />
					<template #footer>
						<base-button type="danger" block size="sm" @click="removePicture(photo)">{{
							$t('general.delete')
						}}</base-button>
					</template>
				</b-card>
			</b-col>
		</b-row>
	</div>
</template>

<script>
let self
import _ from 'lodash'
import {handleError} from '@/utils/func'
export default {
	name: 'PhotoPicker',
	props: {
		images: {
			type: Array			
		},
		id: {
			type: String,
			default: ''
		},
		type: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			imgUrl: `${process.env.VUE_APP_CDNURL.replace('file', 'article')}/`,
			list: [],
			photoList: [],
            loading: false,
		}
	},
	computed: {
	},

	methods: {
		async uploadPictures() {
            self = this;
            self.loading = true;
            self.$store
                .dispatch('ARTICLE_UPLOAD', { files: self.list})
                .then(response => {
                    if (response.status) {
                        self.photoList = response.files;
                        self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                        this.$refs.photos.reset()
                    }
                    self.loading = false;
                })
                .catch(err => {
                    console.log(err)
                    self.loading = false;
                    handleError(self, err);
                });
		},
		async removePicture(uuid) {
			const result = await this.$swal({
				title: this.$i18n.t('quest.sure'),
				text: this.$i18n.t('quest.delete_info'),
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#f5365c',
				confirmButtonText: this.$i18n.t('quest.delete'),
				cancelButtonText: this.$i18n.t('quest.cancel')
			})
			if (result.value) {
                self.$store
                    .dispatch('ARTICLE_IMG_DELETE', uuid)
                    .then(response => {
                        if (response.status) {
                            this.$delete(self.photoList, self.photoList.indexOf(uuid))
                            self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                        }
                    })
                    .catch(err => {
                        handleError(self, err);
                    });
			}
		},

	},
	async created() {
        self = this;
		this.photoList = _.clone(this.images)
	}
}
</script>
